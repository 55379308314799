<script>
import { ArrowUpIcon, ExternalLinkIcon, HelpCircleIcon } from "vue-feather-icons";

import store from "@/store/store";
/**
 * Index-hosting component
 */
export default {

  components: {
    ArrowUpIcon,
    ExternalLinkIcon,
    HelpCircleIcon
  },
  data() {
    return {
      true_hostname: this.$hostname_ba,

      showExtraOptions: false,

      search_line_string: '',
      search_line_date_from: '',
      search_line_date_to: '',

      keywordOptions: [
        { id: 1, value: '' },
        { id: 2, value: 'Герман Греф' },
      ],

      keywordSelected: { id: 1, value: '' },

      searchLine: '',

      search_parameters_obj: {
        query: '',
        limit: '25',
        certainty: '0.5',
        date_from: '',
        date_to: '',
        extra_keywords: [],
        sort_order: 'relevance',
      },

      posts: [],
      posts_count: 0,

      last_date: '',

      customRadio: "relevance",
    };
  },
  watch: {
    search_line_date_from(val) {
      this.search_parameters_obj.date_from = val.toString();
      this.search_line_date_from = val;
      console.log(this.search_line_date_from);
    },
    search_line_date_to(val) {
      this.search_parameters_obj.date_to = val.toString();
      this.search_line_date_to = val;
      console.log(this.search_line_date_to);
    },
    keywordSelected(val) {
      this.search_parameters_obj.extra_keywords = [val.value];
      this.keywordSelected = val;
    },
    search_line_string(val) {
      this.search_parameters_obj.query = val;
      var highlight = this.search_line_string.trim().split(' ').filter((x) => x.length >= 2).join('|');
      this.highlight_regex = new RegExp('(' + highlight + ')', 'ig');
    },
    customRadio(val) {
      this.search_parameters_obj.sort_order = val;
      this.customRadio = val;
    },
  },
  mounted() {
  },
  methods: {
    nullSearch() {
      this.search_line_string = '';
    },
    loadNews(e) {
      console.log('inside loadNews')
      e.preventDefault();

      this.posts = [];
      this.posts_count = 0;

      this.search_parameters_obj.query = this.search_line_string;

      console.log(this.search_parameters_obj);

      this.$vs.loading({
        container: '#div-with-loading',
        scale: 1,
        opacity: 0.1,
      });

      this.axios({
        method: 'post',
        url: this.true_hostname + "/search-weaviate/search_process",
        data: this.search_parameters_obj,
      }).then((res) => {
        if (res.data.news.length) {
          for (var k = 0; k < res.data.news.length; k++) {
            this.posts.push(res.data.news[k]);
          }
        }
        this.posts_count = res.data.news.length;
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");

        //console.log(this.posts);
      }).catch((error) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
      });
    },
    highlightKeyword: function (str) {
      if (str && this.search_line_string) {
        str = ' ' + str + ' ';
        return str.replace(this.highlight_regex, function (matched) {
          return '<span class="bg-soft-primary"">' + matched + '</span class="highlight">';
        });
      }
      else return str;
    },
    get_last_news_date: function () {
      this.axios({
        method: 'post',
        url: this.true_hostname + "/search-weaviate/search_get_last_news_date",
        data: {},
      }).then((res) => {
        this.last_date = res.data.last_date;
      });
    },
  },
  beforeMount() {
    this.get_last_news_date()
  },
};
</script>


<template>
  <div>
    <div class="container mt-5 vs-con-loading__container">
      <div class="row justify-content-center" id="div-with-loading">
        <div class="col-lg-12">
          <h4 class="mb-3" align="center">
            Поиск по статьям из СберПресс
            <span>
              <a href="javascript:void(0)" v-b-modal.HelpForm data-toggle="modal" data-target="#HelpForm"
                style="border: none;" class="text-primary"><help-circle-icon class="fea icon-sm"></help-circle-icon></a>
            </span>
            <b-modal id="HelpForm" title="Справка по сервису" centered button-size="sm" size="lg" ok-only>
              <div class="bg-white p-1 rounded box-shadow">
                <div class="text-muted mb-2" style="font-size: small;">
                  <p>
                    Сейчас Вы видите страницу поиска по базе СберПресс.
                  </p>

                  <p>
                    Для поиска введите текст в поисковую строку и нажмите кнопку Поиск.
                  </p>

                  <p>
                    Есть расширенные опции поиска.
                  <ul style="margin-left: 20px;">
                    <li> Начало периода - дата, начиная с которой, мы смотрим новости. </li>
                    <li> Конец периода - дата конца периода за который мы смотрим новости .</li>
                    <li> Фильтр по авторам цитат - оставляем только те новости, в которых персона встречается как автор
                      цитаты.</li>
                    <li> Сортировка
                      <ul style="margin-left: 20px;">
                        <li> &nbsp; По релевантности - сортируем по метрике похожести новости и введенного запроса.</li>
                        <li> Сначала новые - сортируем по дате новости, сначала идут самые свежие новости, затем старые.
                        </li>
                        <li> Сначала старые - сортируем по дате новости, сначала идут самые старые новости, затем более
                          свежие.</li>
                      </ul>
                    </li>
                  </ul>
                  </p>

                  <p>
                    При возникновении проблем или вопросов:
                  <ul style="margin-left: 20px;">
                    <li> почта - paermolaev@ </li>
                    <li> телеграм - @ermolaevpa </li>
                  </ul>
                  </p>
                </div>
              </div>
            </b-modal>

          </h4>
          <form>
            <div class="row">
              <div class="col-2">
                <span v-if="posts_count">
                  <p class="text-muted mb-1 font-italic" style="font-size: small;" align="center">
                    Всего найдено <strong> {{ posts_count }} </strong> результат(ов)
                  </p>
                </span>

              </div>
              <div class="col-8">
                <div class="form-group mb-3 position-relative">
                  <div class="input-group">
                    <input type="text" class="form-control rounded-left" placeholder="Введите запрос:"
                      v-model="search_line_string" v-on:keyup.enter="loadNews" />
                    <div class="input-group-append">
                      <button class="searchbtn btn btn-primary btn-block" @click="loadNews">
                        Поиск
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2"> </div>
            </div>
            <div class="row mb-2">
              <div class="col-2"> </div>
              <div class="col-4">
                <a class="btn btn-sm btn-outline-primary" v-on:click.prevent="showExtraOptions = ~showExtraOptions">
                  <span v-if="showExtraOptions">
                    Скрыть дополнительные параметры поиска
                  </span>
                  <span v-else>
                    Показать дополнительные параметры поиска
                  </span>
                </a>
              </div>
              <div class="col-4">
                <p class="text-muted mb-1 font-italic" style="font-size: small;" align="right">
                  Последнее сообщение в базе от {{ last_date }}
                </p>
              </div>
              <div class="col-2"> </div>
            </div>
            <div v-if="showExtraOptions" class="mr-1">
              <div class="row">
                <div class="col-2"> </div>
                <div class="col-2">
                  <div class="form-group">
                    <label> Начало периода </label>
                    <input class="form-control" v-model="search_line_date_from" type="date" value="">
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label> Конец периода </label>
                    <input class="form-control" v-model="search_line_date_to" type="date" value="">
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label> Фильтр по авторам цитат </label>
                    <select class="form-control custom-select rounded" v-model="keywordSelected">
                      <option v-bind:value="x" v-for="x in keywordOptions">{{ x.value }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-2"> </div>
              </div>
              <div class="row">
                <div class="col-2"> </div>
                <div class="col-8">
                  <strong>Сортировка: &nbsp; </strong>
                  <div class="custom-control custom-radio custom-control-inline">
                    <div class="form-group mb-0">
                      <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input"
                        v-model="customRadio" value="relevance" />
                      <label class="custom-control-label" for="customRadio1">По релевантности</label>
                    </div>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <div class="form-group mb-0">
                      <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input"
                        v-model="customRadio" value="new" />
                      <label class="custom-control-label" for="customRadio2">Сначала новые</label>
                    </div>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <div class="form-group mb-0">
                      <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input"
                        v-model="customRadio" value="old" />
                      <label class="custom-control-label" for="customRadio3">Сначала старые</label>
                    </div>
                  </div>
                </div>
                <div class="col-2"> </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row" :post="post" :index="ix" v-for="(post, ix) in posts">
        <div class="col-1">
          {{ ix + 1 }}.
        </div>
        <div class="col-11">
          <h5>
            <span v-html="highlightKeyword(post.title)" class="highlight-yellow"></span>
            <a :href="post.url" target="_blank"><external-link-icon class="fea icon-sm"></external-link-icon></a>
          </h5>
          <div class="text-info" style="font-size: small; margin-bottom: 2px; margin-top: 0;">
            {{ post.date }}
          </div>
          <div v-html="highlightKeyword(post.text)" class="highlight-yellow"></div>
          <hr>
        </div>
      </div>
    </div>



  </div>
</template>


<style lang="scss">
.active {
  cursor: pointer;
}

.highlight-yellow .highlight {
  background-color: yellow;
}
</style>